import React, { useState } from "react";



function ForgotPassword() {



    return (
        <>
            <div id="forgotPassword_container">
                <div className="forgotPassword_wrapper">
                    <div className="title_login">
                        <h1>LIberty USSD</h1>
                    </div>
                    <div className="forgotPasswordCard_container">

                        <div className="forgot_passwordheader">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>

                            <div className="forgot_passwordtext">
                                <h6>Forgot Password</h6>
                                <p>A reset password link will be sent to the login email address you enter below.</p>
                            </div>

                        </div>

                        <form className="form_container_forgotPassword">
                            <p className="inputfield_title">Email</p>
                            <div class="input-field">
                                <i class="fas fa-envelope icon___"></i>
                                <input type="email" placeholder="enter Email address" className="email_lowercase"
                                />
                            </div>
                            <button className="send_btn" type="submit">
                                <a href="/resetpassword">Send</a>
                            </button>
                        </form>
                    </div>

                    <div className="footnote">
                        <div className="seperator"></div>
                        <a href="#">LibertyAssured.com</a>
                        <div className="seperator"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;