import React, { useState, useEffect } from "react";

import axios from 'axios'
import moment from "moment"

import Header from "../Layout/header"
import Navigation from "../Layout/Navigation"
import { Table } from 'semantic-ui-react'

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'


function NewRepayment() {

    //  SET STATES 
    const [isDesignated, setIsDesignated] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);


    // FETCH HEADERS
    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
        // setIsDesignated(localStorage.getItem('isDesignation'))
    }, []);

    // CHECK if user is eligible to access APPROVAL
    useEffect(() => {
        var checkDesignation = localStorage.getItem('isDesignation')

        console.log(checkDesignation)

        if (checkDesignation == 'false' || checkDesignation == null) {
            document.getElementById("approve_btn_designation").style.display = "none";
            document.getElementById("approve_btn_selected").style.display = "none";
            // document.getElementById("approvemandate_table").style.display = "none";


        }
        else {
            document.getElementById("approve_btn_designation").style.display = "block"
            // document.getElementById("approve_btn_selected").style.display = "block";
        }
    }, [])



    // preloader
    useEffect(() => {
        showLoader();
        hideLoader();
        showMainLoader();
        hideMainLoader();
    }, [])
    function showLoader() {
        document.getElementById('loadingModaltable').style.display = "block"
    }

    function hideLoader() {
        document.getElementById('loadingModaltable').style.display = "none"
    }
    // END preloader


    // MAIN LOADERS
    function showMainLoader() {
        document.getElementById('loadingModalcard_container').style.display = "block"
    }

    function hideMainLoader() {
        document.getElementById('loadingModalcard_container').style.display = "none"
    }

    // MODAL APPROVE TRANSACTIONS MODAL
    function showSingleReplaymentModal() {
        document.getElementById('singleRepaymentModal').style.display = "block"
    }

    function showMultipleReplaymentModal() {
        document.getElementById('multipleRepaymentModal').style.display = "block"
    }

    function showSelctedReplaymentModal() {
        document.getElementById('selectedRepaymentModal').style.display = "block"
    }

    function showDeleteRepaymentModal() {
        document.getElementById('deleteRepaymentModal').style.display = "block"
    }

    function hideSingleRepaymentModal() {
        document.getElementById('singleRepaymentModal').style.display = "none"
    }

    function hideMultipleRepaymentModal() {
        document.getElementById('multipleRepaymentModal').style.display = "none"
    }
    function hideSelctedReplaymentModal() {
        document.getElementById('selectedRepaymentModal').style.display = "none"
    }
    function hideDeleteRepaymentModal() {
        document.getElementById('deleteRepaymentModal').style.display = "none"
    }
    // END MODAL

    // GET all pending repayments
    const [pendingTransaction, setPendingTransaction] = useState([]);
    const [pendingTransactionID, setPendingTransactionID] = useState([]);
    const [tArray, setTarray] = useState([]);
    const [tArraySingle, setTarraySingle] = useState([]);
    const [tArrayMulti, setTarrayMulti] = useState([]);
    const [saveResponseID, setSavedResponseID] = useState([]);


    useEffect(() => {
        axios.get('https://www.libertyrepayments.com/user/pending/')
            .then((response) => {

                hideLoader();

                setSavedResponseID(response.data.data)
                console.log(response.data.data)

                console.log(response.data.data.length)
                setPendingTransaction(response.data.data);



                // let varData = response.dat  console.log(pendingTransactionID)a.data;

                // console.log(varData)

                // let dataCont = ""



                for (let i = 0; i < response.data.data.length; i++) {
                    // console.log(response.data.data[i].id);
                    // setPendingTransactionID(response.data.data[i].id);
                    tArray.push({
                        "approver_email": localStorage.getItem("user_email"),
                        "payment_id": response.data.data[i].id
                    })
                    // tArray.push(response.data.data[i].id)

                }
                console.log(tArray);
                setPendingTransactionID(tArray);

            })
    }, [])
    // END {GET all pending repayments}

    console.log(pendingTransactionID)
    // END approve all pending transactions



    // search query input field
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)

        // const filteredData = pendingTransaction.filter((item) => {
        //     return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        // })

        // setFilteredResults(filteredData)


        if (searchInput !== '') {
            const filteredData = pendingTransaction.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
            console.log(filteredData)
        }
        else {
            setFilteredResults(pendingTransaction)
        }

        console.log(searchValue)


    }

    // const filteredData = APIData.filter((item) => {
    //     return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
    // })


    // GET DATA FROM TABLE
    const [id, setID] = useState(null)
    const [staff, setStaff] = useState(null)
    const [staffEmail, setStaffEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [mandateID, setRemitaMandateID] = useState('')


    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setStaff(localStorage.getItem('isDesignation'))
        setStaffEmail(localStorage.getItem('user_email'))
        setPhone(localStorage.getItem('phone'))
        setRemitaMandateID(localStorage.getItem('remitaID'))

        console.log(id)
        console.log(staffEmail)
        console.log(staff)
    }, []);
    const setPendingData = (data) => {

        console.log(data);
        let { id, phone, remita_mandate_id } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('phone', phone);
        localStorage.setItem('remitaID', remita_mandate_id);

        showSingleReplaymentModal();


        let dataContainer = data.id
        console.log(dataContainer)

        tArraySingle.push({
            "approver_email": localStorage.getItem("user_email"),
            "payment_id": data.id
        })

        console.log(tArraySingle)




        // for (let i = 0; i < response.data.data.length; i++) {
        //     // console.log(response.data.data[i].id);
        //     // setPendingTransactionID(response.data.data[i].id);

        //     console.log(tArray);
        //     // tArray.push(response.data.data[i].id)

        // }

    }

    // HANDLE SINGLE APPROVAL
    const handleApprove_SinglePending = e => {
        showMainLoader();

        const data = tArraySingle

        axios.post('https://www.libertyrepayments.com/user/approve_loans/', tArraySingle)
            .then(res => {
                console.log(`This is the response ${res}`)
                hideMainLoader();
                hideSingleRepaymentModal();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })

                window.location.reload();
            }).catch(err => {
                hideMainLoader();

                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.response.data.message,
                })


                // window.location.reload()
            })
    }

    // HANDLE ALL APPROVAL
    const handleApprove_allPending = async () => {

        showMainLoader();

        var designationMail = "any@gmail.com"



        const data = tArray

        // payment_id: tArray,
        // approver_email: staffEmail
        // phone: phone,
        // remita_mandate_id: mandateID



        axios.post('https://www.libertyrepayments.com/user/approve_loans/', data)
            .then(res => {
                console.log(`This is the response ${res}`)
                hideMainLoader();
                hideMultipleRepaymentModal();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })
                window.location.reload()

            }).catch(err => {
                hideMainLoader();

                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.response.data.message,
                })


                // window.location.reload()
            })

    }

    // HANDLE MULTIPLE SELECT
    //  GET CHECKBOX DATA
    function GetSelected() {
        //Reference the Table.
        var grid = document.getElementById("Table1");

        //Reference the CheckBoxes in Table.
        var checkBoxes = grid.getElementsByTagName("INPUT");
        var message = "Id Name                  Country\n";

        //Loop through the CheckBoxes.
        for (var i = 0; i < checkBoxes.length; i++) {
            if (checkBoxes[i].checked) {
                var row = checkBoxes[i].parentNode.parentNode;
                message += row.cells[1].innerHTML;
                console.log(row.cells[1])
                // message += "   " + row.cells[2].innerHTML;
                // message += "   " + row.cells[3].innerHTML;
                message += "\n";
            }
        }

        //Display selected Row data in Alert Box.
        alert(message);
    }
    const setPendingDataCheck = (data) => {

        document.getElementById("approve_btn_designation").style.display = "none"
        document.getElementById("approve_btn_selected").style.display = "block";

        console.log(tArraySingle);
        let { id, phone, remita_mandate_id } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('phone', phone);
        localStorage.setItem('remitaID', remita_mandate_id);




        let dataContainer = data.id
        console.log(dataContainer)

        tArrayMulti.push({
            "approver_email": localStorage.getItem("user_email"),
            "payment_id": data.id
        })

        console.log(tArrayMulti)




        // for (let i = 0; i < response.data.data.length; i++) {
        //     // console.log(response.data.data[i].id);
        //     // setPendingTransactionID(response.data.data[i].id);

        //     console.log(tArray);
        //     // tArray.push(response.data.data[i].id)

        // }

    }
    const handleApprove_MultiPending = e => {
        showMainLoader();

        const data = tArraySingle

        axios.post('https://www.libertyrepayments.com/user/approve_loans/', tArrayMulti)
            .then(res => {
                console.log(`This is the response ${res}`)
                hideMainLoader();
                hideSingleRepaymentModal();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })

                window.location.reload();
            }).catch(err => {
                hideMainLoader();

                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.response.data.message,
                })

                // window.location.reload()
            })
    }
    // END  HANDLE MULTIPLE SELECT

    // UPLOAD CSV FUNCTION
    // state to {CSV FILE UPLOAD}
    let [csvFile, setCsvFile] = useState(null);
    // END state to {CSV FILE UPLOAD}

    function openCSVuploadModal() {
        document.getElementById('uploadCSVmodal').style.display = "block";
    }
    function hideOpenCSVmodal() {
        //     document.getElementById('uploadCSVmodal').style.display = "none";
    }

    const data = new FormData();

    useEffect(() => {
        const form = document.querySelector("#formCSV"),
            fileInput = document.querySelector(".file-input"),
            progressArea = document.querySelector(".progress-area"),
            uploadedArea = document.querySelector(".uploaded-area");
        form.addEventListener("click", () => {
            fileInput.click();
        });
        fileInput.onchange = ({ target }) => {
            let file = target.files[0];
            if (file) {
                let fileName = file.name;
                if (fileName.length >= 12) {
                    let splitName = fileName.split('.');
                    fileName = splitName[0].substring(0, 13) + "... ." + splitName[1];
                }
                uploadFile(fileName);
            }
        }
        function uploadFile(name) {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", "php/upload.php");
            xhr.upload.addEventListener("progress", ({ loaded, total }) => {
                let fileLoaded = Math.floor((loaded / total) * 100);
                let fileTotal = Math.floor(total / 1000);
                let fileSize;
                (fileTotal < 1024) ? fileSize = fileTotal + " KB" : fileSize = (loaded / (1024 * 1024)).toFixed(2) + " MB";
                let progressHTML = `<li class="row">
                          <i class="fas fa-file-alt"></i>
                          <div class="content">
                            <div class="details">
                              <span class="name">${name} • Uploading</span>
                              <span class="percent">${fileLoaded}%</span>
                            </div>
                            <div class="progress-bar">
                              <div class="progress" style="width: ${fileLoaded}%"></div>
                            </div>
                          </div>
                        </li>`;
                uploadedArea.classList.add("onprogress");
                progressArea.innerHTML = progressHTML;
                if (loaded == total) {
                    progressArea.innerHTML = "";
                    let uploadedHTML = `<li class="row">
                            <div class="content upload">
                              <i class="fas fa-file-alt"></i>
                              <div class="details">
                                <span class="name">${name} • Uploaded</span>
                                <span class="size">${fileSize}</span>
                              </div>
                            </div>
                            <i class="fas fa-check"></i>
                          </li>`;
                    uploadedArea.classList.remove("onprogress");
                    uploadedArea.insertAdjacentHTML("afterbegin", uploadedHTML);
                }
            });
            let data = new FormData(form);
            xhr.send(data);
        }
    }, [])

    // HANDLE SUBMIT CSV
    const handleSubmitCsv = (event) => {
        event.preventDefault();

        csvFile = document.querySelector('#input_file_');

        data.append("file", csvFile.files[0]);
        console.log(`This is the upload payload ${csvFile} and ${data.keys}`)

        showLoader();


        axios.post("https://www.libertyrepayments.com/user/upload_csv/", data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            // alert("Upload successful");
            console.log(response)
            hideLoader();

            Swal.fire({
                title: 'Upoad Successful!',
                text: 'Do you want to continue',
                icon: 'sucess',
                confirmButtonText: 'Okay'
            })

            // window.location.reload()

        }).catch((error) => {
            hideLoader();

            Swal.fire({
                title: 'Error!',
                text: 'Do you want to continue',
                icon: 'error',
                confirmButtonText: 'Cancel'
            })

            // window.location.reload()
        })
    }
    // END UPLOAD CSV FUNCTION

    // CURREENCY FORMATER
    const amountNumberFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN'
        }).format(value);


    // DELETE RECORD : function delete record
    const [deleteRecord, setDeleteRecord] = useState([]);
    const [deleteRecordRemitaID, setDeleteRecordRemitaID] = useState();
    const [tArrayDelete, setTarrayDelete] = useState([]);

    const setDeleteData = (data) => {
        console.log(data);

        let { id, remita_mandate_id } = data;
        setDeleteRecord(id)
        setDeleteRecordRemitaID(remita_mandate_id)
        localStorage.setItem('deletePendingID', id);
        showDeleteRepaymentModal();


        tArrayDelete.push({
            "id": data.id
        })

        console.log(tArrayDelete)

        // setDeleteRecord(localStorage.getItem('deletePendingID'));
    }
    console.log(deleteRecord)

    // handle delete function
    const handleDeleteRecord = e => {
        showMainLoader();

        const data = tArrayDelete;

        axios.delete('https://www.libertyrepayments.com/user/delete_pending/', tArrayDelete)
            .then(res => {
                console.log(`This is the response ${res}`)
                hideMainLoader();
                hideSingleRepaymentModal();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })

                window.location.reload();
            }).catch(err => {
                hideMainLoader();

                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.response.data.message,
                })


                // window.location.reload()
            })
    }

    return (
        <>

            <div id="loadingModalcard_container">
                <div className="loadingModalWrapper">
                    <div className="loadingModalbox">
                        <h1>Loading........</h1>
                    </div>
                </div>
            </div>

            <Header />
            <Navigation />


            {/* // approve SINGLE repayment */}
            <div id="singleRepaymentModal">
                <div className="multipleRepaymentModalWrapper">
                    <div className="multipleRepaymentModalCard">
                        <h1>Confirm to approve pending repayment</h1>
                        <div className="multiplerepay_buttoncontainer">
                            <button onClick={hideSingleRepaymentModal} className="decline_btn">Decline</button>
                            <button onClick={handleApprove_SinglePending} className="continue_btn">Continue</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* // approve MULTIPLE repayment */}
            <div id="multipleRepaymentModal">
                <div className="multipleRepaymentModalWrapper">
                    <div className="multipleRepaymentModalCard">
                        <h1>Confirm to approve all pending repayment</h1>
                        <div className="multiplerepay_buttoncontainer">
                            <button onClick={hideMultipleRepaymentModal} className="decline_btn">Decline</button>
                            <button onClick={handleApprove_allPending} className="continue_btn">Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* // approve SELECTED repayment */}
            <div id="selectedRepaymentModal">
                <div className="multipleRepaymentModalWrapper">
                    <div className="multipleRepaymentModalCard">
                        <h1>Confirm to approve selected pending repayment</h1>
                        <div className="multiplerepay_buttoncontainer">
                            <button onClick={hideSelctedReplaymentModal} className="decline_btn">Decline</button>
                            <button onClick={handleApprove_MultiPending} className="continue_btn">Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* // Delete Record */}
            <div id="deleteRepaymentModal">
                <div className="multipleRepaymentModalWrapper">
                    <div className="multipleRepaymentModalCard">
                        <h1>Confirm to delete pending repayment <br />
                            <span> Remita ID: {deleteRecordRemitaID} </span></h1>
                        <div className="multiplerepay_buttoncontainer">
                            <button onClick={hideDeleteRepaymentModal} className="decline_btn">Decline</button>
                            <button onClick={handleDeleteRecord} className="continue_btn">Continue</button>
                        </div>
                    </div>
                </div>
            </div>



            {/* UPLOAD CSV MODAL */}
            <div id="uploadCSVmodal" onClick={hideOpenCSVmodal}>
                <div className="uploadCSVModalWrapper">
                    <div className="uploadCSVModalCard">
                        <h1>Upload CSV</h1>

                        <div className="wrapper">
                            <form id="formCSV">
                                <div class="form_container">
                                    {/* <input class="file-input" type="file" name="file"
                            onChange={(e) => setCsvFile(e.target.files)}
                            required
                            hidden /> */}
                                    <input id='input_file_' class="file-input" type="file" name="file" accept=".csv"
                                        onChange={(e) => setCsvFile(e.target.files)}
                                        required
                                        hidden
                                    />
                                    <i class="fas fa-cloud-upload-alt"></i>
                                    <p>Browse File to Upload</p>
                                </div>

                                <section class="progress-area"></section>
                                <section class="uploaded-area"></section>
                            </form>

                        </div>

                        <button className="uploadCSVbutton"
                            onClick={handleSubmitCsv}>Submit</button>
                    </div>
                </div>
            </div>

            <div className="pendingtransaction_container">
                <div className="pendingtransaction_wrapper">
                    <div className="pendingtransaction_header">
                        <h1>Pending  Repayments</h1>

                        {/* <div className="search_container">
                            <input type="text" />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div> */}

                        <div className="search_download_container">
                            <div className="search_container">
                                <input type="text"
                                    onChange={(e) => searchItems(e.target.value)} />
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>

                            <div className="button_flex">
                                <button className="upload_csv_btn" onClick={openCSVuploadModal}>Upload <i class="fa-solid fa-upload"></i></button>
                                <button>Download <i class="fa fa-cloud-download" aria-hidden="true"></i></button>
                                <button id="approve_btn_designation" className="approveall_btn" onClick={showMultipleReplaymentModal}>Approve all</button>
                                <button id="approve_btn_selected" className="approve_selected_btn" onClick={showSelctedReplaymentModal}>Approve Selected</button>
                            </div>


                        </div>

                        <div className="table_container">
                            <div id="loadingModaltable">
                                <div className="loadingModalWrapper">
                                    <div className="loadingModalCard">
                                        <h1>Loading........</h1>
                                    </div>
                                </div>
                            </div>
                            <Table id="Table1" className="span_table">
                                <Table.Header>
                                    <Table.Row className="templatetransactionalTableHeader">
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                                        <Table.HeaderCell>User</Table.HeaderCell>
                                        <Table.HeaderCell>Remita mandate ID</Table.HeaderCell>
                                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        <Table.HeaderCell>Phone no.</Table.HeaderCell>
                                        <Table.HeaderCell>Method of Payment</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Loan disk ID</Table.HeaderCell> */}
                                        <Table.HeaderCell id="approvemandate_table">Approve</Table.HeaderCell>
                                        <Table.HeaderCell>Edit record</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Disbursment  Date</Table.HeaderCell>
                                        <Table.HeaderCell>Transaction Date</Table.HeaderCell> */}
                                        <Table.HeaderCell>Delete record</Table.HeaderCell>
                                        <Table.HeaderCell>Posted time</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {searchInput.length > 1 ? (
                                        filteredResults.map((data) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>
                                                        <input type="checkbox"
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>{data.id}</Table.Cell>
                                                    <Table.Cell>{data.username}</Table.Cell>
                                                    <Table.Cell>{data.remita_mandate_id}</Table.Cell>
                                                    <Table.Cell>{data.full_name}</Table.Cell>
                                                    <Table.Cell>{amountNumberFormat(data.amount)}</Table.Cell>
                                                    <Table.Cell>{data.phone}</Table.Cell>
                                                    <Table.Cell>{data.payment_method}</Table.Cell>
                                                    <Table.Cell id="approvemandate_button">
                                                        <button className="approve_button_pending"
                                                            onClick={() => setPendingData(data)}>
                                                            <i class="fa-solid fa-thumbs-up"></i>
                                                            Approve
                                                        </button>
                                                    </Table.Cell>
                                                    <Table.Cell> <button className="status_pendingtrans">
                                                        <i class="fa-solid fa-pen"></i>
                                                        Edit
                                                    </button>
                                                    </Table.Cell>
                                                    <Table.Cell> <button className="delete_pendingtrans">
                                                        <i class="fa-solid fa-trash"></i>
                                                        Delete
                                                    </button>
                                                    </Table.Cell>
                                                    <Table.Cell>{moment(data.payment_date).format("dddd, MMMM Do YYYY")}</Table.Cell>

                                                </Table.Row>
                                            )
                                        })
                                    ) : (
                                        pendingTransaction.map((data) => {

                                            var checkDesignation = localStorage.getItem('isDesignation')

                                            // console.log(checkDesignation)
                                            if (checkDesignation == 'false' || checkDesignation == null) {
                                                return (

                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <input type="checkbox" />
                                                        </Table.Cell>
                                                        <Table.Cell>{data.id}</Table.Cell>
                                                        <Table.Cell>{data.username}</Table.Cell>
                                                        <Table.Cell>{data.remita_mandate_id}</Table.Cell>
                                                        <Table.Cell>{data.full_name}</Table.Cell>
                                                        <Table.Cell>{amountNumberFormat(data.amount)}</Table.Cell>
                                                        <Table.Cell>{data.phone}</Table.Cell>
                                                        <Table.Cell>{data.payment_method}</Table.Cell>
                                                        <Table.Cell id="approvemandate_column">
                                                            <button className="approve_button_pending"
                                                                onClick={() => setPendingData(data)}
                                                                disabled
                                                                style={{ opacity: '0.5' }}
                                                            >
                                                                <i class="fa-solid fa-thumbs-up"></i>
                                                                Approve
                                                            </button>
                                                        </Table.Cell>
                                                        <Table.Cell> <button className="status_pendingtrans">
                                                            <i class="fa-solid fa-pen"></i>
                                                            Edit
                                                        </button>
                                                        </Table.Cell>
                                                        <Table.Cell> <button className="delete_pendingtrans">
                                                            <i class="fa-solid fa-trash"></i>
                                                            Delete
                                                        </button>
                                                        </Table.Cell>
                                                        <Table.Cell>{moment(data.payment_date).format("dddd, MMMM Do YYYY")}</Table.Cell>

                                                    </Table.Row>
                                                )
                                            }
                                            else {
                                                return (

                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <input type="checkbox"
                                                                onChange={() => setPendingDataCheck(data)}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>{data.id}</Table.Cell>
                                                        <Table.Cell>{data.username}</Table.Cell>
                                                        <Table.Cell>{data.remita_mandate_id}</Table.Cell>
                                                        <Table.Cell>{data.full_name}</Table.Cell>
                                                        <Table.Cell>{amountNumberFormat(data.amount)}</Table.Cell>
                                                        <Table.Cell>{data.phone}</Table.Cell>
                                                        <Table.Cell>{data.payment_method}</Table.Cell>
                                                        <Table.Cell id="approvemandate_column">
                                                            <button className="approve_button_pending"
                                                                onClick={() => setPendingData(data)}
                                                            >
                                                                <i class="fa-solid fa-thumbs-up"></i>
                                                                Approve
                                                            </button>
                                                        </Table.Cell>
                                                        <Table.Cell> <button className="status_pendingtrans">
                                                            <i class="fa-solid fa-pen"></i>
                                                            Edit
                                                        </button>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <button className="delete_pendingtrans" onClick={() => setDeleteData(data)}>
                                                                <i class="fa-solid fa-trash"></i>
                                                                Delete
                                                            </button>
                                                        </Table.Cell>
                                                        <Table.Cell>{moment(data.payment_date).format("dddd, MMMM Do YYYY")}</Table.Cell>

                                                    </Table.Row>
                                                )
                                            }


                                        })
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewRepayment;