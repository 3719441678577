import React, { useState } from "react";


import Header from "../Layout/header"
import Navigation from "../Layout/Navigation"
import { Table } from 'semantic-ui-react'


import borrowersimg from '../../images/borrowers.svg'
import totalamount from '../../images/totalamount.svg'
import walletbalance from '../../images/walletbalance.svg'


function NewRepayment() {



    return (
        <>
            <Header />
            <Navigation />

            <div className="marketer_container">
                <div className="marketer_wrapper">
                    <div className="marketer_card_container">
                        <div className="marketer_card_header">
                            <h1><span>Mark</span>eting</h1>
                        </div>
                        <div className="marketer_card_content">
                            <div className="borrowers">
                                <img src={borrowersimg} alt="icon" />
                                <p>Eligible borrowers</p>
                                <h1>200</h1>

                            </div>
                            <div className="totalamount">
                                <img src={totalamount} alt="icon" />
                                <p>Total amount</p>
                                <h1>N3,000,000</h1>
                            </div>
                            <div className="walletbalance">
                                <img src={walletbalance} alt="icon" />
                                <p>Wallet Balance</p>
                                <h1>N20,000</h1>
                            </div>
                        </div>

                        <div className="sendmsg_btn">
                            <button>Send Message</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default NewRepayment;