import React, { useEffect, useState } from "react";
import axios from 'axios'

import profileimage from "../../images/profile.svg"
import newrepaymentIcon from "../../images/newrepaymenticon.svg"
import pendingtransaction from "../../images/pendingtransaction.svg"
import approvedtransaction from "../../images/approvedTransaction.svg"
import pendingcashback from "../../images/pendingcashback.svg"
import loanee from "../../images/loanimg.svg"
import users from "../../images/users.svg"
import marketing from "../../images/marketingicon.svg"
import repayment from "../../images/repayments.svg"


function Navigation() {

    const [allRepayments, setAllRepayments] = useState("");
    const [approved_repayment, setApproved_repayment] = useState("");
    const [pending_repayment, setPending_repayment] = useState("");


    // useEffect(() => {
    //     setAllRepayments(localStorage.getItem('all_repayment'))
    //     setApproved_repayment(localStorage.getItem('approved_repayment'))
    //     setPending_repayment(localStorage.getItem('pending_repayment'))
    // }, [])

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
    }, []);


    const [approvedTransaction, setApprovedTransaction] = useState([]);
    useEffect(() => {
        axios.get('https://www.libertyrepayments.com/user/repayment_count/')
            .then((response) => {
                console.log(response)

                setAllRepayments(response.data.all_repayment)
                setApproved_repayment(response.data.approved_count)
                setPending_repayment(response.data.pending_count)
            })
    }, [])



    return (
        <>
            <div className="Navigation_container">
                <div className="Navigation_wrapper">
                    <a tabindex="4" href="/newrepayment" className="Navigation_repaymentcard">
                        <div>
                            <img src={newrepaymentIcon} alt="icon" />
                            <h1><i class="fa fa-plus-square" aria-hidden="true"></i>new payment</h1>
                        </div>
                    </a>


                    <a tabindex="4" href="/pendingtransactions" className="Navigation_pendingtransaction">
                        <div>
                            <img src={pendingtransaction} alt="icon" />
                            <h6>Pending Repayments</h6>
                            <h1>{pending_repayment}</h1>
                        </div>
                    </a>


                    <a tabindex="4" href="/approvedtransactions" className="Navigation_approvedtransaction">
                        <div>
                            <img src={approvedtransaction} alt="icon" />
                            <h6>Approved Repayments</h6>
                            <h1>{approved_repayment}</h1>
                        </div>
                    </a>


                    <a tabindex="4" href="/pendingcashack" className="Navigation_approvedtransaction">
                        <div>
                            <img src={pendingcashback} alt="icon" />
                            <h6>Pending Cash Back</h6>
                            <h1>{70}</h1>
                        </div>
                    </a>

                    <a tabindex="4" href="/approvedcashack" className="Navigation_approvedtransaction">
                        <div>
                            <img src={approvedtransaction} alt="icon" />
                            <h6>Approved Cash Back</h6>
                            <h1>70</h1>
                        </div>
                    </a>
                    <a tabindex="4" href="/loanee" className="Navigation_users">
                        <div>
                            <img src={loanee} alt="icon" />
                            <h6>Loanee</h6>
                            <h1>4</h1>
                        </div>
                    </a>
                    <a tabindex="4" href="/allrepayments" className="Navigation_users">
                        <div>
                            <img src={repayment} alt="icon" />
                            <h6>Repayments</h6>
                            <h1>{allRepayments}</h1>
                        </div>
                    </a>
                    <a tabindex="4" href="/users" className="Navigation_users">
                        <div>
                            <img src={users} alt="icon" />
                            <h6>Users</h6>
                            <h1>4</h1>
                        </div>
                    </a>

                    <a tabindex="4" href="/marketing" className="Navigation_marketing">
                        <div>
                            <img src={marketing} alt="icon" />
                            <h6>Marketing</h6>
                            <h1></h1>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
}

export default Navigation;