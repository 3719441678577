import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import './App.scss';

// import paths
import Login from "./components/pages/login";
import ForgotPassword from "./components/pages/forgotpassword";
import ResetPassword from "./components/pages/resetpassword";
import MainDashNewRepayment from "./components/pages/Newrepayment";
import PendingTransactions from "./components/pages/pendingtransactions";
import ApprovedTransactions from "./components/pages/approvedtransaction";
import PendingCashback from "./components/pages/pendingcashback";
import Approvedcashbback from "./components/pages/approvedcashback";
import Loanee from "./components/pages/loanee";
import Users from "./components/pages/users";
import Marketing from "./components/pages/marketer";
import AllRepayments from "./components/pages/allrepayment";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/forgotpassword" component={ForgotPassword} exact />
        <Route path="/resetpassword" component={ResetPassword} exact />
        <Route path="/newrepayment" component={MainDashNewRepayment} exact />
        <Route path="/pendingtransactions" component={PendingTransactions} exact />
        <Route path="/approvedtransactions" component={ApprovedTransactions} exact />
        <Route path="/pendingcashack" component={PendingCashback} exact />
        <Route path="/approvedcashack" component={Approvedcashbback} exact />
        <Route path="/loanee" component={Loanee} exact />
        <Route path="/users" component={Users} exact />
        <Route path="/marketing" component={Marketing} exact />
        <Route path="/allrepayments" component={AllRepayments} exact />
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
