import React, { useState, useEffect } from "react";


import Header from "../Layout/header"
import Navigation from "../Layout/Navigation"
import { Table } from 'semantic-ui-react'


function NewRepayment() {

    // useEffect(() => {
    //     axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
    // }, []);


    const [pendingCashback, setPendingCashback] = useState([]);
    useEffect(() => {

    }, [])

    return (
        <>
            <Header />
            <Navigation />

            <div className="pendingcashback_container">
                <div className="pendingcashback_wrapper">
                    <div className="pendingtransaction_header">
                        <h1>Pending  Cash back</h1>

                        <div className="search_download_container">
                            <div className="search_container">
                                <input type="text" />
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>

                            <div>
                                <button>Download <i class="fa fa-cloud-download" aria-hidden="true"></i></button>
                                <button className="approveall_btn">Approve all</button>
                            </div>


                        </div>

                        <div className="table_container">
                            <Table className="span_table">
                                <Table.Header>
                                    <Table.Row className="templatetransactionalTableHeader">
                                        <Table.HeaderCell>S/N</Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Remita mandate ID</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        <Table.HeaderCell>Phone no.</Table.HeaderCell>
                                        <Table.HeaderCell>Method of Payment</Table.HeaderCell>
                                        <Table.HeaderCell>Loan disk ID</Table.HeaderCell>
                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                        <Table.HeaderCell>Disbursment  Date</Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>01</Table.Cell>
                                        <Table.Cell>Joseph emmauel</Table.Cell>
                                        <Table.Cell>LD-123456</Table.Cell>
                                        <Table.Cell>N35,000</Table.Cell>
                                        <Table.Cell>08102812328</Table.Cell>
                                        <Table.Cell>Bank transfer</Table.Cell>
                                        <Table.Cell>LD-123456</Table.Cell>
                                        <Table.Cell className="status_color">
                                            Pending
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button className="approve_btn">
                                                Approve
                                            </button>
                                        </Table.Cell>
                                        <Table.Cell>14 - 01 - 22</Table.Cell>
                                    </Table.Row>

                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewRepayment;