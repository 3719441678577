import React, { useState, useEffect } from "react";
import axios from 'axios'
import moment from "moment"

import Header from "../Layout/header"
import Navigation from "../Layout/Navigation"
import { Table } from 'semantic-ui-react'

// import readXlsxFile from 'read-excel-file'


function NewRepayment() {
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    // preloader
    function showLoader() {
        document.getElementById('loadingModaltable').style.display = "block"
    }

    function hideLoader() {
        document.getElementById('loadingModaltable').style.display = "none"
    }
    // preloader

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
    }, []);


    const [approvedTransaction, setApprovedTransaction] = useState([]);
    useEffect(() => {
        axios.get('https://www.libertyrepayments.com/user/all_repayment/')
            .then((response) => {
                hideLoader();
                setApprovedTransaction(response.data.data);

                console.log(response)
            })
    }, [])

    // search query input field
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)

        // const filteredData = pendingTransaction.filter((item) => {
        //     return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        // })

        // setFilteredResults(filteredData)


        if (searchInput !== '') {
            const filteredData = approvedTransaction.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
            console.log(filteredData)
        }
        else {
            setFilteredResults(approvedTransaction)
        }

        console.log(searchValue)


    }


    return (
        <>
            <Header />
            <Navigation />

            <div className="approvedtransaction_container">
                <div className="approvedtransaction_wrapper">
                    <div className="approvedtransaction_header">
                        <h1>All Repayments</h1>


                        <div className="search_download_container">
                            <div className="search_container">
                                <input type="text"
                                    onChange={(e) => searchItems(e.target.value)} />
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>

                            {/* <button>Download <i class="fa-solid fa-cloud-arrow-down"></i></button> */}

                            {/* <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="tbl_data"
                                filename="test"
                                sheet="tablexls"
                                buttonText="Download"
                            /> */}

                        </div>

                        <div className="table_container">
                            <div id="loadingModaltable">
                                <div className="loadingModalWrapper">
                                    <div className="loadingModalCard">
                                        <h1>Loading........</h1>
                                    </div>
                                </div>
                            </div>
                            <Table className="span_table" id="tbl_data">
                                <Table.Header>
                                    <Table.Row className="templatetransactionalTableHeader">
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                                        <Table.HeaderCell>Phone no.</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        <Table.HeaderCell>Remita mandate ID</Table.HeaderCell>
                                        <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                                        <Table.HeaderCell>Method of Payment</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Loan disk ID</Table.HeaderCell> */}
                                        {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Disbursment  Date</Table.HeaderCell>
                                        <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                                        <Table.HeaderCell>Posted time</Table.HeaderCell> */}
                                    </Table.Row>
                                </Table.Header>


                                <Table.Body>
                                    {searchInput.length > 1 ? (
                                        filteredResults.map((data) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{data.id}</Table.Cell>
                                                    <Table.Cell>{data.phone}</Table.Cell>
                                                    <Table.Cell>{data.amount}</Table.Cell>
                                                    <Table.Cell>{data.remita_mandate_id}</Table.Cell>
                                                    <Table.Cell>{moment(data.payment_date).format("dddd, MMMM Do YYYY")}</Table.Cell>
                                                    <Table.Cell>{data.payment_method}</Table.Cell>
                                                    {/* <Table.Cell className="status_color">
                                                        Pending
                                                    </Table.Cell> */}
                                                    <Table.Cell> <button className="status_pendingtrans">
                                                        <i class="fa-solid fa-pen"></i>
                                                        Edit
                                                    </button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    ) : (

                                        approvedTransaction.map((data) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{data.id}</Table.Cell>
                                                    <Table.Cell>{data.phone}</Table.Cell>
                                                    <Table.Cell>{data.amount}</Table.Cell>
                                                    <Table.Cell>{data.remita_mandate_id}</Table.Cell>
                                                    <Table.Cell>{moment(data.payment_date).format("dddd, MMMM Do YYYY")}</Table.Cell>
                                                    <Table.Cell>{data.payment_method}</Table.Cell>
                                                    {/* <Table.Cell className="status_color">
                                                        Pending
                                                    </Table.Cell> */}
                                                    <Table.Cell> <button className="status_pendingtrans">
                                                        <i class="fa-solid fa-pen"></i>
                                                        Edit
                                                    </button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })

                                    )}

                                </Table.Body>

                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewRepayment;