import React from "react";



function ResetPassword() {



    return (
        <>
            <div id="resetPassword_container">
                <div className="resetPassword_wrapper">
                    <div className="title_login">
                        <h1>LIberty USSD</h1>
                    </div>
                    <div className="resetPasswordCard_container">

                        <div className="reset_passwordheader">
                            <div className="reset_passwordtext">
                                <h6>Reset ssword</h6>
                            </div>

                        </div>

                        <form className="form_container_resetPassword">

                            <div className="inputfield_container">
                                <p className="inputfield_title">New Password</p>
                                <div class="input-passwordfield">
                                    <i class="fas fa-lock toggle-password icon___"></i>
                                    <input type="password" placeholder="Password" id="password-field" required />
                                    <span><i toggle="#password-field" class="fas fa-eye toggle-password icon___"></i></span>
                                    {/* <i class="fas fa-lock"></i> */}
                                </div>
                            </div>
                            <div className="inputfield_container">
                                <p className="inputfield_title">Confirm Password</p>
                                <div class="input-passwordfield">
                                    <i class="fas fa-lock toggle-password icon___"></i>
                                    <input type="password" placeholder="Password" id="password-field" required />
                                    <span><i toggle="#password-field" class="fas fa-eye toggle-password icon___"></i></span>
                                    {/* <i class="fas fa-lock"></i> */}
                                </div>
                            </div>

                            <button className="send_btn" type="submit">
                                <a href="/resetpassword">Reset Password</a>
                            </button>
                        </form>
                    </div>

                    <div className="footnote">
                        <div className="seperator"></div>
                        <a href="#">LibertyAssured.com</a>
                        <div className="seperator"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;