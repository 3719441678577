import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios'

import profileimage from "../../images/profile.svg"

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'




function Header() {

    let history = useHistory();

    const [userToken, setUserToken] = useState("");

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
    }, []);

    const handleLogoutBtn = e => {

        // const headers = {
        //     Authorization: `token ${userToken}`,
        // };

        // console.log(headers)

        // axios.post('https://www.libertyrepayments.com/user/logout/', headers)
        //     .then(res => {
        //         console.log(`This is the response ${res}`)


        //     }).catch(err => {


        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Oooops',
        //             text: err.response.data.message,
        //         })
        //     })

        localStorage.clear("Token")
        history.push('/login')


    }

    useEffect(() => {
        // var today = new Date();
        // var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        // document.getElementById("currentDate").value = date;

        var today = new Date();
        document.getElementById('currentDate').innerHTML = today;
    }, [])




    return (
        <>
            <div className="header_container">
                <div className="header_wrapper">
                    <h1>Liberty USSD</h1>

                    <div className="others">
                        <p id="currentDate"></p>
                        <i class="fa fa-bell" aria-hidden="true"></i>

                        <div class="navigation">
                            <div class="button" href="" onClick={handleLogoutBtn}>
                                <img src={profileimage} />

                                <div class="logout">Logout</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Header;