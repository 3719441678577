import React, { useState, useEffect } from "react";
import axios from 'axios'

import Header from "../Layout/header"
import Navigation from "../Layout/Navigation"
import { Table } from 'semantic-ui-react'


function NewRepayment() {



    // preloader
    function showLoader() {
        document.getElementById('loadingModaltable').style.display = "block"
    }

    function hideLoader() {
        document.getElementById('loadingModaltable').style.display = "none"
    }
    // preloader


    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
    }, []);


    const [users, setUsers] = useState([]);
    useEffect(() => {
        axios.get('https://www.libertyrepayments.com/user/api/users/')
            .then((response) => {
                hideLoader();
                setUsers(response.data);

                console.log(response)
            })
    }, [])


    return (
        <>
            <Header />
            <Navigation />

            <div className="users_container">
                <div className="users_wrapper">
                    <div className="users_card_container">
                        <div className="users_card_header">
                            <h1>Users</h1>

                            <div className="headerSearchContainer">
                                <input type="text" placeholder="search" />
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="users_table_container">
                            <div id="loadingModaltable">
                                <div className="loadingModalWrapper">
                                    <div className="loadingModalCard">
                                        <h1>Loading........</h1>
                                    </div>
                                </div>
                            </div>

                            <Table className="span_table">
                                <Table.Header>
                                    <Table.Row className="templatetransactionalTableHeader">
                                        <Table.HeaderCell>User ID</Table.HeaderCell>
                                        <Table.HeaderCell>Email</Table.HeaderCell>
                                        <Table.HeaderCell>Password</Table.HeaderCell>
                                        <Table.HeaderCell>Designation</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {users.map((data) => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell>{data.id}</Table.Cell>
                                                <Table.Cell>{data.name}</Table.Cell>
                                                <Table.Cell>{data.user}</Table.Cell>
                                                <Table.Cell>{data.designation}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewRepayment;