import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory, withRouter } from 'react-router-dom';
import axios from 'axios'
import $ from 'jquery'

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

function Login() {

    let history = useHistory();

    // Declaring input States
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userDesignation, setUserDesignation] = useState("");


    useEffect(() => {
        $(".toggle-password").click(function () {

            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });

        $(".toggle-password1").click(function () {

            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
        $(".toggle-password2").click(function () {

            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
    })

    function showLoader() {
        document.getElementById('loadingModal').style.display = "block"
    }

    function hideLoader() {
        document.getElementById('loadingModal').style.display = "none"
    }

    const handleLoginSubmit = e => {
        e.preventDefault();


        showLoader();

        const data = {
            email: userEmail,
            password: userPassword,
            designation: userDesignation
        }

        console.log(`This is the login data ${data.email} and ${data.password}`)



        // https://manual-repayment.herokuapp.com/user/login/
        // showLoader();
        axios.post('https://www.libertyrepayments.com/user/login/', data)
            .then(res => {
                console.log(res)

                localStorage.setItem('agentToken', res.data.token);
                localStorage.setItem('isDesignation', res.data.designation);
                localStorage.setItem('user_email', res.data.user_email);
                localStorage.setItem('all_repayment', res.data.data.all_repayment);
                localStorage.setItem('approved_repayment', res.data.data.approved_repayment);
                localStorage.setItem('pending_repayment', res.data.data.pending_repayment);


                hideLoader();

                history.push('/newrepayment')
            }).catch(err => {
                console.log(err)
                hideLoader();

                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.response.data.message,
                })
            })
    }

    return (
        <>
            <div id="loadingModal">
                <div className="loadingModalWrapper">
                    <div className="loadingModalCard">
                        <h1>Loading........</h1>
                    </div>
                </div>
            </div>

            <div id="login_container">
                <div className="login_wrapper">
                    <div className="login_card_container">
                        <div className="title_login">
                            <h1>Liberty Repayments</h1>
                        </div>

                        <form className="form_container"
                            onSubmit={handleLoginSubmit}>
                            <div class="input-field">
                                <i class="fas fa-user icon___"></i>
                                <input type="email" placeholder="Email" className="email_lowercase"
                                    onChange={e => setUserEmail(e.target.value)}
                                />
                            </div>
                            <div class="input-passwordfield">
                                <i class="fas fa-lock toggle-password icon___"></i>
                                <input type="password" placeholder="Password" id="password-field" required
                                    onChange={e => setUserPassword(e.target.value)} />
                                <span><i toggle="#password-field" class="fas fa-eye toggle-password icon___"></i></span>
                                {/* <i class="fas fa-lock"></i> */}
                            </div>
                            {/* <div class="input-field">
                                <i class="fa fa-building icon___"></i>
                                <select
                                    required
                                    onChange={e => setUserDesignation(e.target.value)} >
                                    <option disabled selected="true">Designation</option>
                                    <option value="customercare">Customer Care</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div> */}
                            <button className="login_btn" type="submit">Log in</button>
                            {/* <div className="forgot_password">
                                <a href="/forgotpassword">forgot password?</a>
                            </div> */}
                        </form>


                        <div className="footnote">
                            <div className="seperator"></div>
                            <a href="/newrepayment">LibertyAssured.com</a>
                            <div className="seperator"></div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Login;