import React, { useState, useEffect } from "react";
import axios from 'axios'

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'


import Header from "../Layout/header"
import Navigation from "../Layout/Navigation"


function NewRepayment() {


    // preloader
    function showLoader() {
        document.getElementById('loadingModal').style.display = "block"
    }

    function hideLoader() {
        document.getElementById('loadingModal').style.display = "none"
    }
    // preloader


    // preloader
    function showMultipleReplaymentModal() {
        document.getElementById('multipleRepaymentModal').style.display = "block"
    }

    function hideMultipleRepaymentModal() {
        document.getElementById('multipleRepaymentModal').style.display = "none"
    }
    // preloader

    // Declaring input States
    const [repaymentAmount, setRepaymentAmount] = useState("");
    const [repaymentPaymentMethod, setRepaymentPaymentMethod] = useState("");
    const [repaymentTransactionDate, setrepaymentTransactionDate] = useState("");
    const [repaymentRemitaMandateID, setRepaymentRemitaMandateID] = useState("");
    const [repaymentPhoneNumber, setRepaymentPhoneNumber] = useState("");
    const [getReturnedPhoneNumber, setGetReturnedPhoneNumber] = useState("");


    const [getMultipleRepaymentMessage, setGetMultipleRepaymentMessage] = useState("");

    const [userToken, setUserToken] = useState("");




    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('agentToken');
    }, []);


    const handleRepaymentubmit = e => {
        e.preventDefault();

        showLoader();

        const headers = {
            Authorization: `token ${userToken}`,
        };

        const data = {
            amount: repaymentAmount,
            payment_method: repaymentPaymentMethod,
            payment_date: repaymentTransactionDate,
            remita_mandate_id: repaymentRemitaMandateID,
            phone: repaymentPhoneNumber
        }

        console.log(`This is the repayment data ${repaymentAmount} and 
        ${repaymentPaymentMethod}, ${repaymentTransactionDate},${repaymentRemitaMandateID},${repaymentPhoneNumber}`)

        console.log(headers)


        // showLoader();
        axios.post('https://www.libertyrepayments.com/user/repayment/', data)
            .then(res => {
                console.log(`This is the response ${res}`)
                hideLoader();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })


                // set phone number and message for multiple repayment
                setGetReturnedPhoneNumber(res.data.phone)
                setGetMultipleRepaymentMessage(res.data.message)


                console.log(getReturnedPhoneNumber)
                console.log("tedt")
                // display modal for multiple repayment
                if (res.data.status === 302) {
                    showMultipleReplaymentModal();
                }
                // End display modal for multiple repayment

            }).catch(err => {
                hideLoader();

                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.response.data.message,
                })
                console.log(`This is the response ${err}`)
            })
    }

    const handleConfirmDuplicate = e => {
        showLoader();
        console.log(getReturnedPhoneNumber)

        const data = {
            phone: getReturnedPhoneNumber
        }


        axios.post('https://www.libertyrepayments.com/user/confirm_duplicate/', data)
            .then(res => {
                console.log(res)
                hideLoader();
                Swal.fire({
                    icon: 'success',
                    text: res.data.message,
                })

                hideMultipleRepaymentModal();

            }).catch(err => {
                hideLoader();
                Swal.fire({
                    icon: 'error',
                    title: 'Oooops',
                    text: err.reponse,
                })
                console.log(err)
            })
    }




    return (
        <>
            <Header />
            <Navigation />

            <div id="loadingModal">
                <div className="loadingModalWrapper">
                    <div className="loadingModalCard">
                        <h1>Loading........</h1>
                    </div>
                </div>
            </div>

            <div id="multipleRepaymentModal">
                <div className="multipleRepaymentModalWrapper">
                    <div className="multipleRepaymentModalCard">
                        <h1>{getMultipleRepaymentMessage}</h1>
                        <div className="multiplerepay_buttoncontainer">
                            <button onClick={hideMultipleRepaymentModal} className="decline_btn">Decline</button>
                            <button onClick={handleConfirmDuplicate} className="continue_btn">Continue</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="Repayment_container">
                <div className="Repayment_card">
                    <div className="repayment_card_title">
                        <h1>Repayment</h1>
                    </div>

                    <form
                        onSubmit={handleRepaymentubmit}>
                        <div className="repayment_form">
                            <div className="form_section1">
                                {/* <div className="form_input_container">
                                    <p>Name</p>
                                    <input type="text" />
                                </div> */}
                                <div className="form_input_container">
                                    <p>Amount</p>
                                    <input type="number"
                                        onChange={e => setRepaymentAmount(e.target.value)} />
                                </div>
                                <div className="form_input_container">
                                    <p>Method of Payment</p>
                                    <select
                                        onChange={e => setRepaymentPaymentMethod(e.target.value)} >
                                        <option>Select method of payment</option>
                                        <option value="transfer">Transfer</option>
                                        <option value="deposit">Deposit</option>
                                        <option value="cash">Cash</option>
                                        <option value="cheque">Cheque</option>
                                    </ select>
                                </div>
                                <div className="form_input_container">
                                    <p>Transaction Date</p>
                                    <input type="datetime-local"
                                        onChange={e => setrepaymentTransactionDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="form_section2">
                                <div className="form_input_container">
                                    <p>Remita mandate ID</p>
                                    <input type="text"
                                        onChange={e => setRepaymentRemitaMandateID(e.target.value)} />
                                </div>
                                <div className="form_input_container">
                                    <p>Phone number</p>
                                    <input type="text"
                                        onChange={e => setRepaymentPhoneNumber(e.target.value)} />
                                </div>
                                {/* <div className="form_input_container">
                                    <p>Loan disk ID</p>
                                    <input type="text" />
                                </div> */}
                                {/* <div className="form_input_container">
                                    <p>Posted date</p>
                                    <input type="Date" />
                                </div> */}
                            </div>
                        </div>

                        <div className="postrepaymentbtn">
                            <button type="submit">POST</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default NewRepayment;